import React from "react";
import styled from "styled-components";
// Components
import BlogBox from "../Elements/BlogBox";
import FullButton from "../Buttons/FullButton";
import TestimonialSlider from "../Elements/TestimonialSlider";

export default function Blog() {
  return (
    <Wrapper id="blog">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Blog Stories</h1>
            <p className="font13">
              Welcome to Our Blog Stories, where insights meet innovation. Dive into our latest musings on technology,
              design, and the magic behind what we do. Here, we share the stories that shape us, the projects that inspire us,
              and the ideas that push us forward.
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="The Future of Design: Where We're Headed"
                text="Explore the evolving world of design with us as we delve into the latest trends, technologies, and strategies driving the future. From sustainable design to digital innovation, discover how we're crafting tomorrow's visual language."
                tag="Innovation"
                author="Jane Doe, 3 days ago"
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="Behind the Scenes: Our Creative Process"
                text="Ever wondered how ideas come to life? Join us behind the scenes as we unveil our creative process, from the spark of inspiration to the final masterpiece. It's a journey of creativity, collaboration, and passion."
                tag="Creativity"
                author="John Smith, 5 days ago"
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="Tech Trends Shaping Our Solutions"
                text="Stay ahead of the curve with us as we break down the tech trends shaping our solutions. From AI to blockchain, see how we're integrating cutting-edge technologies to deliver unparalleled services."
                tag="Technology"
                author="Alex Johnson, 1 week ago"
                action={() => alert("clicked")}
              />
            </div>
          </div>
          <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
              <FullButton title="Load More" action={() => alert("clicked")} />
            </div>
          </div>
        </div>
      </div>
      <div className="lightBg" style={{padding: '50px 0'}}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">What They Say?</h1>
            <p className="font13">
              Discover the Voices of Our Community in 'What They Say?'. Here, our clients and partners share their experiences,
              the impact of our collaborative projects, and the lasting value we've created together. Their stories inspire our continuous evolution.
            </p>
          </HeaderInfo>
          <TestimonialSlider />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
