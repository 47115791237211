import React from "react";
import { Helmet } from 'react-helmet';
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";
import Blog from "../components/Sections/Blog";
import Pricing from "../components/Sections/Pricing";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer";

export default function Landing() {
  return (
    <>
      <Helmet>
        <title>Massarwa - Innovating Digital Design & Development</title>
        <meta name="description" content="Massarwa brings your digital dreams to life with cutting-edge design and development services. From graphic design to custom software solutions, discover excellence in every project." />
        <meta name="keywords" content="Massarwa, EarthSense, Agrima Enterprise Holding LTD, Agrima Industries, MassCivil, Mohamed Massarwa, digital design, web development, graphic design, software development, app development, creative agency" />
        <meta property="og:title" content="Massarwa - Innovating Digital Design & Development" />
        <meta property="og:description" content="Elevate your business with Massarwa's bespoke digital design and development services. From captivating graphic designs to robust software solutions, we turn ideas into reality." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.massarwa.com" />
      </Helmet>
      <TopNavbar />
      <Header />
      <Services />
      <Projects />
      <Blog />
      <Contact />
      <Footer />
    </>
  );
}
