import * as React from "react";

function SvgComponent(props) {
  return (
    <svg width={50} height={50} viewBox="0 0 64 64" id="TwoTone" xmlns="http://www.w3.org/2000/svg">
      <title/>
      <desc/>
      <g data-name="Stroke copy 3" id="Stroke_copy_3">
        <path d="M50,3H14a2,2,0,0,0-2,2V59a2,2,0,0,0,2,2H50a2,2,0,0,0,2-2V5A2,2,0,0,0,50,3ZM45,41a3,3,0,0,1-3,3H38A18,18,0,0,1,20,26V15a4,4,0,0,1,4-4H41a4,4,0,0,1,4,4Z" style={{fill:"#a8dba8"}}/>
        <path d="M16,7V49H48V7ZM45,41a3,3,0,0,1-3,3H38A18,18,0,0,1,20,26V15a4,4,0,0,1,4-4H41a4,4,0,0,1,4,4Z" style={{fill:"#cff09e"}}/>
        <rect height="4" style={{fill:"#79bd9a"}} width="8" x="28" y="53"/>
        <path d="M26,14H41a1,1,0,0,1,1,1v7a0,0,0,0,1,0,0H26a4,4,0,0,1-4-4v0A4,4,0,0,1,26,14Z" style={{fill:"#a8dba8"}}/>
        <path d="M22,18V34a4,4,0,0,0,4,4H41a1,1,0,0,0,1-1V22H26A4,4,0,0,1,22,18Z" style={{fill:"#79bd9a"}}/>
      </g>
      <g data-name="Stroke Close copy 3" id="Stroke_Close_copy_3">
        <path d="M41,13H26a5,5,0,0,0-5,5V34a5,5,0,0,0,5,5H41a2,2,0,0,0,2-2V15A2,2,0,0,0,41,13ZM26,15H41v2H39a1,1,0,0,0,0,2h2v2H26a3,3,0,0,1,0-6Zm0,22a3,3,0,0,1-3-3V22a4.92,4.92,0,0,0,3,1H41V37Z" style={{fill:"#3b8686"}}/>
        <path d="M32,25H30a1,1,0,0,0-1,1v8a1,1,0,0,0,1,1h2a3,3,0,0,0,3-3,3,3,0,0,0-.78-2A3,3,0,0,0,35,28,3,3,0,0,0,32,25Zm-1,2h1a1,1,0,0,1,0,2H31Zm1,6H31V31h1a1,1,0,0,1,0,2Z" style={{fill:"#3b8686"}}/>
        <path d="M26,19h9a1,1,0,0,0,0-2H26a1,1,0,0,0,0,2Z" style={{fill:"#3b8686"}}/>
        <path d="M48,2H16a5,5,0,0,0-5,5V57a5,5,0,0,0,5,5H48a5,5,0,0,0,5-5V7A5,5,0,0,0,48,2Zm3,55a3,3,0,0,1-3,3H16a3,3,0,0,1-3-3V7a3,3,0,0,1,3-3H48a3,3,0,0,1,3,3Z" style={{fill:"#3b8686"}}/>
        <path d="M47,6H17a2,2,0,0,0-2,2V48a2,2,0,0,0,2,2H47a2,2,0,0,0,2-2V8A2,2,0,0,0,47,6ZM17,48V8H47V48Z" style={{fill:"#3b8686"}}/>
        <path d="M36,52H28a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V53A1,1,0,0,0,36,52Zm-1,4H29V54h6Z" style={{fill:"#3b8686"}}/>
        <path d="M38,42H26a1,1,0,0,0,0,2H38a1,1,0,0,0,0-2Z" style={{fill:"#3b8686"}}/>
      </g>
    </svg>
  );
}

export default SvgComponent;