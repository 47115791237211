import * as React from "react";
import logo from '../img/logo/logo-with-text.png'; 

function LogoComponent() {
  const logoStyle = {
    width: '100%', // or any other value
    height: 'auto', // maintain aspect ratio
    maxWidth: '140px' // or any other value
  };

  return (
    <img src={logo} alt="Logo" style={logoStyle} />
  );
}

export default LogoComponent;